<template>
  <div class="baseConsoleTablePage hasTopButton">
    <div class="pageTopButton">
      <!--   这是上传图片按钮的权限 暂时去掉 -->
      <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          v-if="inspectButton('coi:asc:upi')"
          @click="uploadDialog = true"
        >{{ $t("uploadImg") }}</el-button>
    </div>
    <div class="tableBlock">
      <el-table :data="tableData" style="width: 100%" height="100" v-tableHeight="{bottomOffset: 74}" :tableLoading="loading" ref="tableRefer" >
        <!-- 图片 -->
        <el-table-column :label="$t('image')" min-width="120px" >
          <template slot-scope="scope">
            <el-image
              :fit="'contain'"
              style="width: 100px; height: 68px; background: #f2f2f2"
              :src="scope.row.url"
              :preview-src-list="[scope.row.url]"
            ></el-image>
          </template>
        </el-table-column>
        <!-- 协会类型 -->
        <el-table-column  :label="$t('societyType')" min-width="124px" >
          <template slot-scope="scope">
            <div>
              <span :title="scope.row.certificate_type">{{ scope.row.certificate_type | certificateFormat(scope.row.name, LOCALE) }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 有效期限 -->
        <el-table-column :label="$t('termOfValidity')" min-width="130px" >
          <template slot-scope="scope">
            <div>
              <span style="font-size: 12px" v-if="scope.row.audit_status == 1"
                >{{ scope.row.start_time | secondFormat("LL") }} -
                {{ scope.row.end_time | secondFormat("LL") }}</span
              >
              <span style="font-size: 12px" v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <!-- 上传时间 -->
        <el-table-column :label="$t('uploadTime')" min-width="130px" >
          <template slot-scope="scope">
            <div>
              <span style="font-size: 12px">{{ scope.row.created_time | secondFormat }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column :label="$t('status')" min-width="90px" >
          <template slot-scope="scope">
            <div v-if="scope.row.audit_status == 1">
              <div v-if="scope.row.overdue">
                <span style="display: inline-block;width: 7px;height: 7px;background: #f56c6c;border-radius: 50%;"></span>
                <span style="line-height: 23px; font-size: 12px">{{$t("expired")}}</span>
              </div>
              <div v-else>
                <span style="display: inline-block;width: 7px;height: 7px;background: #52c41a;border-radius: 50%;"></span>
                <span style="line-height: 23px; font-size: 12px">{{$t("normal")}}</span>
              </div>
            </div>
            <div v-if="scope.row.audit_status == 0">
              <span style="display: inline-block;width: 7px;height: 7px;background: #409eff;border-radius: 50%;"></span>
              <span style="line-height: 23px; font-size: 12px">{{ $t("inReview") }}</span>
            </div>
            <div v-if="scope.row.audit_status == 2">
              <span style="display: inline-block;width: 7px;height: 7px;background: #e6a23c;border-radius: 50%;"></span>
              <span style="line-height: 23px; font-size: 12px">{{ $t("aduitReject") }}</span>
              <el-popover
                placement="top-start"
                :title="LOCALE == 'en' ? 'Failure cause' : '失败原因'"
                width="200"
                trigger="click"
                :content="scope.row.reason"
              >
                <i slot="reference" class="el-icon-question" style="color: #e6a23c; cursor: pointer" ></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column :label="$t('handle')" min-width="50px" >
          <template slot-scope="scope">
            <!--  v-if="inspectButton('coi:asc:del')" :disabled="inspectHandle('coi:asc:del')" 这是下面按钮的权限 暂时去掉 -->
            <el-button type="text"  v-if="inspectButton('coi:asc:del')" :disabled="inspectHandle('coi:asc:del')"  size="small" @click="deletePicture(scope.row.id)" >{{ $t("delete") }}</el-button >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total,prev, pager, next"
        :page-size="limit"
        @current-change="pageChange"
        :total="total"
        :current-page="page"
      ></el-pagination>
    </div>
    <el-dialog :title="$t('uploadImg')"  :visible.sync="uploadDialog" width="670px" :destroy-on-close="true" >
      <uploadImg  @closeDialog="closeDialog" :options="{company_id: USER_INFO.company_id,type: 'qualification',}" @uploadSuccess="uploadSuccess"></uploadImg>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from "@/baseComponents/uploadListDialog";
import { certificateList } from "@/baseUtils/enumValue";
export default {
  async asyncData({ store, query }) {},
  head(e) {
    if (e.LOCALE == "en") {
      return {
        title: "Enterprise Settings-Industry Certification",
      };
    } else {
      return {
        title: "企业设置-行业证书",
      };
    }
  },
  data() {
    return {
      certificateList,
      uploadDialog: false,
      tableData: [],
      total: 0,
      limit:10,
      page: 1,
      loading: false,
    };
  },
  components: { uploadImg },
  async mounted(){
    this.getList();
  },
  methods: {
    //请求数据列表
    async getList() {
      this.loading = true;
      try {
        let params = {
          limit: this.limit,
          company_id: this.USER_INFO.company_id,
          start: this.page - 1,
        };

        let data = await this.$store.dispatch(
          "baseConsole/company_getCertificateList",
          params
        );
        if (data.success) {
          data.data.forEach((element) => {
            element.overdue = false;
            if (element.audit_status == 1) {
              if (element.end_time < Date.parse(new Date()) / 1000) {
                element.overdue = true;
              }
            }
          });
          this.tableData = data.data;
          this.total = data.total;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    //分页请求
    pageChange(e) {
      this.page = e;
      this.getList();
    },
    // 关闭上传图片的弹框
    closeDialog() {
      this.uploadDialog = false;
    },
    // 图片上传成功
    uploadSuccess() {
      this.uploadDialog = false;
      this.RefreshPagesData();
    },
    //图片上传成功
    async RefreshPagesData(page = false) {
      try {
        this.page = page ? page : 1;
        let params = {
          limit: this.limit,
          company_id: this.USER_INFO.company_id,
          start: page ? page - 1 : 0,
        };

        let data = await this.$store.dispatch(
          "baseConsole/company_getCertificateList",
          params
        );

        if (data.success) {
          data.data.forEach((element) => {
            element.overdue = false;
            if (element.audit_status == 1) {
              if (element.end_time < Date.parse(new Date()) / 1000) {
                element.overdue = true;
              }
            }
          });
          if (data.data.length) {
            this.tableData = data.data;
          } else {
            this.pageIsEmpty();
          }

          this.total = data.total;
        }
      } catch (e) {}
    },
    async pageIsEmpty() {
      try {
        this.page = this.page - 1;
        let params = {
          limit: this.limit,
          company_id: this.USER_INFO.company_id,
          start: this.page > 0 ? this.page - 1 : 0,
        };

        let data = await this.$store.dispatch(
          "baseConsole/company_getCertificateList",
          params
        );

        if (data.success) {
          data.data.forEach((element) => {
            element.overdue = false;
            if (element.audit_status == 1) {
              if (element.end_time < Date.parse(new Date()) / 1000) {
                element.overdue = true;
              }
            }
          });
          this.tableData = data.data;
          this.total = data.total;
        }
      } catch (e) {}
    },
    // 删除
    deletePicture(id) {
      this.$confirm(
        this.$t("confirmDelete"),
        this.$t("delete"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          closeOnClickModal: false,
          type: "warning",
        }
      ).then(async () => {
          try {
            let params = {
              certificate_id: id,
              company_id: this.USER_INFO.company_id,
            };
            let data = await this.$store.dispatch(
              "baseConsole/company_deleteCertificate",
              params
            );
            if (data.success) {
              this.$delTip();
              this.RefreshPagesData(this.page);
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
</style>